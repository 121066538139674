// .modal-open            - body class for killing the scroll
// .modal                     - container to scroll within
// .modal-dialog        - positioning shell for the actual modal
// .modal-content     - actual modal w/ bg and corners and stuff

// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    // Prevent Chrome on Windows from adding a focus outline. For details, see
    // https://github.com/twbs/bootstrap/pull/10951.
    outline: 0;
    // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
    // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    // See also https://github.com/twbs/bootstrap/issues/17695

    .modal-open & {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .close:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    .modal-header .close {
        padding: 1rem;
        margin: -1rem -1rem -1rem auto;
    }
    button.close {
        padding: 0;
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
    [type='reset'],
    [type='submit'],
    button,
    html [type='button'] {
        -webkit-appearance: button;
    }
    .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
    }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    // allow clicks to pass through for custom click handling to close modal
    pointer-events: none;

    // When fading in the modal, animate it to slide down
    .modal.fade & {
        transition: transform 0.3s ease-out;
        transform: translate(0, -25%);
    }
    .modal.show & {
        transform: translate(0, 0);
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

// Actual modal
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    pointer-events: auto;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    // Remove focus outline from opened modal
    outline: 0;
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000 !important;

    // Fade for backdrop
    &.fade {
        opacity: 0;
    }
    &.show {
        opacity: 0.5 !important;
    }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    display: flex;
    align-items: flex-start; // so the close btn always stays on the upper right corner
    justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;

    .close {
        padding: 1rem;
        // auto on the left force icon to the right even when there is no .modal-title
        margin: -1rem -1rem -1rem auto;
    }
}

// Title text within header
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when should there be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: 1rem;
}

// Footer (for actions)
.modal-footer {
    display: flex;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: 1rem;
    border-top: 1px solid #e9ecef;

    // Easily place margin between footer elements
    > :not(:first-child) {
        margin-left: 0.25rem;
    }
    > :not(:last-child) {
        margin-right: 0.25rem;
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

// Scale up the modal
@media (min-width: 576px) {
    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }

    .modal-sm {
        max-width: 300px !important;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }
}
