.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;

    &.alert-danger {
        color: #b17279;
        background-color: #f6ebec;
        border-color: #ebdadc;
    }

    &.alert-success {
        color: #4a5a3c;
        background-color: #e8f0e3;
        border-color: #d7e8d1;
    }

    &.alert-info {
        color: #3a4a5a;
        background-color: #e8f0f6;
        border-color: #d7e8f0;
    }

    &.alert-warning {
        color: #5a4a3a;
        background-color: #f6f0e8;
        border-color: #f0e8d7;
    }

    &.alert-light {
        color: #5a5a5a;
        background-color: #f8f9fa;
        border-color: #f1f3f5;
    }

    &.alert-dark {
        color: #3a3a3a;
        background-color: #e9ecef;
        border-color: #dee2e6;
    }
}
