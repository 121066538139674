.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;

    .card-header {
        padding: 0.75rem 1rem;
        display: grid;
        grid-auto-flow: column;

        .card-title {
            font-weight: 600;
            font-size: 1rem;
            display: flex;
            align-items: center;
        }

        .card-header-actions {
            display: flex;
            flex-direction: row;
        }
    }

    .card-body {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 5px 10px;
        overflow-x: auto;
    }

    .card-footer {
        padding: 0.75rem 1.25rem;
        background-color: rgba(0, 0, 0, 0.03);
        border-top: 0 solid rgba(0, 0, 0, 0.125);
    }
}
