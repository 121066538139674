.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

@for $i from 0 through 1 {
    .mt-#{$i},
    .my-#{$i} {
        margin-top: $i * 0.25rem !important;
    }

    .mb-#{$i},
    .my-#{$i} {
        margin-bottom: $i * 0.25rem !important;
    }

    .ml-#{$i},
    .mx-#{$i} {
        margin-left: $i * 0.25rem !important;
    }

    .mr-#{$i},
    .mx-#{$i} {
        margin-right: $i * 0.25rem !important;
    }
}

@for $i from 2 through 4 {
    .mt-#{$i},
    .my-#{$i} {
        margin-top: ($i - 1) * 0.5rem !important;
    }

    .mb-#{$i},
    .my-#{$i} {
        margin-bottom: ($i - 1) * 0.5rem !important;
    }

    .ml-#{$i},
    .mx-#{$i} {
        margin-left: ($i - 1) * 0.5rem !important;
    }

    .mr-#{$i},
    .mx-#{$i} {
        margin-right: ($i - 1) * 0.5rem !important;
    }
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

@for $i from 0 through 1 {
    .pt-#{$i},
    .py-#{$i} {
        padding-top: $i * 0.25rem !important;
    }

    .pb-#{$i},
    .py-#{$i} {
        padding-bottom: $i * 0.25rem !important;
    }

    .pl-#{$i},
    .px-#{$i} {
        padding-left: $i * 0.25rem !important;
    }

    .pr-#{$i},
    .px-#{$i} {
        padding-right: $i * 0.25rem !important;
    }
}

@for $i from 2 through 4 {
    .pt-#{$i},
    .py-#{$i} {
        padding-top: ($i - 1) * 0.5rem !important;
    }

    .pb-#{$i},
    .py-#{$i} {
        padding-bottom: ($i - 1) * 0.5rem !important;
    }

    .pl-#{$i},
    .px-#{$i} {
        padding-left: ($i - 1) * 0.5rem !important;
    }

    .pr-#{$i},
    .px-#{$i} {
        padding-right: ($i - 1) * 0.5rem !important;
    }
}

.float-right {
    float: right !important;
}
