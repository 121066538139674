@import '~@ng-select/ng-select/themes/default.theme.css';

html,
body {
    background-color: #42434b;

    margin: 0;
    padding: 0;
}

* {
    font-family: 'Mulish', sans-serif;
}

// Setting cursor: pointer to al a tags (even if they don't have href)
a {
    cursor: pointer;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.avatar {
    border: 2px solid rgb(197, 197, 197);

    display: flex;
    border-radius: 50%;

    width: fit-content;

    img {
        width: 30px;
        height: 30px;
        margin: 3px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    }
}

.date-container {
    .main,
    .date {
        font-size: 0.9rem;
    }

    .secondary,
    .hour {
        font-size: 0.8rem;
        color: #c5c7cd;
    }
}

.rank-span {
    background: #f0f0f0;
    border-radius: 100px;
    padding: 0.4rem 0.7rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: 90px;

    text-transform: uppercase;

    &.rank-admin {
        background: #f12b2c;
        color: #fff;
    }

    &.rank-gerente {
        background: #fec400;
        color: #fff;
    }

    &.rank-ingeniero {
        background: #29cc97;
        color: #fff;
    }
}

button.no-btn {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.small-card-filters {
    input {
        padding: 5px 10px;
        margin-right: 6px;
        border: 1px solid #e7e7e7;
        max-width: 250px;
        margin-left: auto;
    }

    button {
        border: 1px solid #dfe0eb;
        outline: none !important;
        box-shadow: none !important;
        padding: 3px 7px;
        color: #ababab;
        background: #fbfbfb;
        font-size: 20px;

        &.active i {
            animation: fa-spin 0.4s linear infinite;
        }
    }
}

.nav-tabs {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    .nav-item {
        background-color: #ffffff;
        display: flex;
        border: 1px solid #dfe0eb;

        border-right-width: 0;
        &:last-of-type {
            border-right-width: 1px;
        }

        a {
            padding: 0.5rem 1rem;
            color: #9fa2b4;
            text-decoration: none;
        }

        &.active {
            border-color: #dfe0eb;
            border-bottom-color: transparent;
            box-shadow: 0px 1px white;
            a {
                color: #3751ff;
            }
        }
    }
}

.filter-btn {
    border: none;
    background: none;
    outline: none;

    display: flex;
    align-items: center;

    svg {
        width: 25px;
        height: 25px;

        margin: 0 5px;

        path {
            fill: #c5c7cd;
        }
    }

    span {
        font-size: 1rem;
        color: #4b506d;
    }

    &:disabled {
        opacity: 50%;
    }
}

.custom-table {
    background: #ffffff;

    border: 1px solid #dfe0eb;
    border-radius: 8px;

    margin-top: 1.5rem;
    &.table-embed-top {
        margin-top: 0;
        border-top-left-radius: 0;
    }

    &.table-no-border {
        border: none;
    }

    .table-header {
        display: grid;
        grid-template-columns: 1fr fit-content(1px);
        align-items: center;

        padding: 5px 1.5rem;

        .table-title {
            h3 {
                margin-top: 1rem;
            }
        }
    }

    .table-body {
        .table-row {
            display: grid;
            grid-auto-flow: column;
            align-items: center;

            width: -webkit-fill-available;
            padding: 5px 1.5rem;
            color: #9fa2b4;

            strong {
                color: #4b506d;
            }

            &.table-cols {
                border-bottom: 1.5px solid #dfe0eb;
            }
        }
    }

    .table-cols .sorting {
        padding-right: 2rem;
        user-select: none;
        cursor: pointer;

        &:before,
        &:after,
        &_asc:before,
        &_asc:after,
        &_desc:before,
        &_desc:after,
        &_asc_disabled:before,
        &_asc_disabled:after,
        &_desc_disabled:before,
        &_desc_disabled:after {
            position: absolute;
            bottom: 0.9em;
            display: block;
            opacity: 0.3;
            color: #626262;
        }

        &,
        &_asc,
        &_desc,
        &_asc_disabled,
        &_desc_disabled {
            cursor: pointer;
            position: relative;
        }

        &:before,
        &_asc:before,
        &_desc:before,
        &_asc_disabled:before,
        &_desc_disabled:before {
            right: 1em;
            content: '↑';
            bottom: 3px;
        }

        &:after,
        &_asc:after,
        &_desc:after,
        &_asc_disabled:after,
        &_desc_disabled:after {
            right: 0.5em;
            content: '↓';
            bottom: 3px;
        }

        &_asc:before,
        &_desc:after {
            opacity: 1;
            color: #2d9cdb;
        }
    }
}

.tab-content {
    .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }
}

@import './scss/main.scss';
