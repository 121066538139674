.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &:enabled {
        cursor: pointer;
        &:hover {
            background-color: #e9ecef;
            border-color: #e9ecef;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
    }

    &:disabled {
        opacity: 50%;
    }

    &.btn-small {
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
        line-height: 1.5;
        border-radius: 0.2rem;
    }

    &.btn-primary {
        color: #fff;
        background-color: #2d9cdb;
        border-color: #2d9cdb;

        &:enabled {
            &:hover {
                background-color: #2a86ff;
                border-color: #2a86ff;
            }
        }

        &:disabled {
            background-color: #2078ac;
            border-color: #2078ac;
        }
    }

    &.btn-success {
        color: #fff;
        background-color: #29cc97;
        border-color: #29cc97;

        &:enabled {
            &:hover {
                background-color: #24b886;
                border-color: #24b886;
            }
        }

        &:disabled {
            background-color: #1da57a;
            border-color: #1da57a;
        }
    }

    &.btn-warning {
        color: #fff;
        background-color: #f2994a;

        &:enabled {
            &:hover {
                background-color: #f39c12;
                border-color: #f39c12;
            }
        }

        &:disabled {
            background-color: #e08e0b;
            border-color: #e08e0b;
        }
    }

    &.btn-export,
    &.btn-refresh {
        border: 1px solid #dfe0eb;
        outline: none !important;
        box-shadow: none !important;
        color: #ababab;
        background: #fbfbfb;
        font-size: 20px;
    }

    &.btn-danger {
        color: #fff;
        background-color: #eb5757;
        border-color: #eb5757;

        &:enabled {
            &:hover {
                background-color: #e3342f;
                border-color: #e3342f;
            }
        }

        &:disabled {
            background-color: #c82333;
            border-color: #c82333;
        }
    }

    &.i-spin i {
        animation: fa-spin 0.4s linear infinite;
    }
}

.btn-loading {
    color: transparent !important;

    user-select: none;

    position: relative;
    &::before {
        color: #ffffff !important;
        font-family: 'Font Awesome 6 Free';
        font-weight: 900;
        content: '\f1ce';

        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -10px;
        margin-top: -10px;

        -webkit-animation: fa-spin 0.4s linear infinite;
        animation: fa-spin 0.4s linear infinite;
    }
}
